import * as React from 'react'

export default function Logo() {
  return (
    <svg
      width="80"
      height="27"
      viewBox="0 0 80 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M34.4828 6.89655V5.51724C34.4828 4.75547 33.8652 4.13793 33.1035 4.13793C32.3417 4.13793 31.7241 4.75547 31.7241 5.51724C31.7241 5.51724 31.7241 6.01307 31.7241 6.55172C31.7241 9.65517 38.6207 7.93103 38.6207 15.1724C38.6207 15.9804 38.6207 16.5517 38.6207 16.5517C38.6207 19.5988 36.1505 22.069 33.1035 22.069C30.0564 22.069 27.5862 19.5988 27.5862 16.5517V15.1724H31.7241V16.5517C31.7241 17.3134 32.3417 17.931 33.1035 17.931C33.8652 17.931 34.4828 17.3134 34.4828 16.5517C34.4828 16.5517 34.4828 15.9804 34.4828 15.1724C34.4828 11.2069 27.5862 13.4483 27.5862 6.55172C27.5862 6.01307 27.5862 5.51724 27.5862 5.51724C27.5862 2.47016 30.0564 0 33.1035 0C36.1505 0 38.6207 2.47016 38.6207 5.51724V6.89655H34.4828Z"
          fill="var(--text-primary)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.4138 16.5517V5.51724C52.4138 2.47016 49.9436 0 46.8966 0C43.8495 0 41.3793 2.47016 41.3793 5.51724V16.5517V26.8966H45.5173V21.8952C45.9581 22.0086 46.4204 22.069 46.8966 22.069C49.9436 22.069 52.4138 19.5988 52.4138 16.5517ZM48.2759 6.2069V15.8621C48.2759 16.6238 47.6583 17.2414 46.8966 17.2414C46.1348 17.2414 45.5173 16.6238 45.5173 15.8621V6.2069C45.5173 5.44512 46.1348 4.82759 46.8966 4.82759C47.6583 4.82759 48.2759 5.44512 48.2759 6.2069Z"
          fill="var(--text-primary)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.6897 17.931C61.4514 17.931 62.069 17.3134 62.069 16.5517V15.1724H66.2069V16.5517C66.2069 19.5988 63.7367 22.069 60.6897 22.069C57.6426 22.069 55.1724 19.5988 55.1724 16.5517V5.51724C55.1724 2.47016 57.6426 0 60.6897 0C63.7367 0 66.2069 2.47016 66.2069 5.51724V8.96552V12.4138H62.069H59.3104V15.1724V16.5517C59.3104 17.3134 59.9279 17.931 60.6897 17.931ZM59.3104 5.51724C59.3104 4.75547 59.9279 4.13793 60.6897 4.13793C61.4514 4.13793 62.069 4.75547 62.069 5.51724V8.96552H59.3104V5.51724Z"
          fill="var(--text-primary)"
        />
        <path
          d="M74.4828 4.13793C73.721 4.13793 73.1034 4.75547 73.1034 5.51724V16.5517C73.1034 17.3134 73.721 17.931 74.4828 17.931C75.2445 17.931 75.8621 17.3134 75.8621 16.5517V15.1724H80V16.5517C80 19.5988 77.5298 22.069 74.4828 22.069C71.4357 22.069 68.9655 19.5988 68.9655 16.5517V5.51724C68.9655 2.47016 71.4357 0 74.4828 0C77.5298 0 80 2.47016 80 5.51724V6.89655H75.8621V5.51724C75.8621 4.75547 75.2445 4.13793 74.4828 4.13793Z"
          fill="var(--text-primary)"
        />
        <path
          d="M11.0345 5.51724H5.51724V11.0345H0V16.5517H5.51724V22.069H11.0345V16.5517H16.5517V11.0345H11.0345V5.51724Z"
          fill="var(--text-primary)"
        />
        <path
          d="M22.069 0H16.5517H11.0345V5.51724H16.5517V11.0345H22.069V5.51724V0Z"
          fill="#747E8D"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="80" height="26.8966" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
